import React, { Suspense, Fragment, lazy } from 'react';
import { Switch, Redirect, Route } from 'react-router-dom';
import DashboardLayout from 'src/layouts/DashboardLayout';
import DocsLayout from 'src/layouts/DocsLayout';
import MainLayout from 'src/layouts/MainLayout';
import LoadingScreen from 'src/components/LoadingScreen';
import AuthGuard from 'src/components/AuthGuard';
import GuestGuard from 'src/components/GuestGuard';

export const renderRoutes = (routes = []) => (
  <Suspense fallback={<LoadingScreen />}>
    <Switch>
      {routes.map((route, i) => {
        const Guard = route.guard || Fragment;
        const Layout = route.layout || Fragment;
        const Component = route.component;

        return (
          <Route
            key={i}
            path={route.path}
            exact={route.exact}
            render={props => (
              <Guard>
                <Layout>
                  {route.routes ? (
                    renderRoutes(route.routes)
                  ) : (
                    <Component {...props} />
                  )}
                </Layout>
              </Guard>
            )}
          />
        );
      })}
    </Switch>
  </Suspense>
);

const routes = [
  {
    exact: true,
    path: '/404',
    component: lazy(() => import('src/views/errors/NotFoundView')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/login',
    component: lazy(() => import('src/views/auth/LoginView')),
  },
  {
    exact: true,
    guard: GuestGuard,
    path: '/register',
    component: lazy(() => import('src/views/auth/RegisterView')),
  },
  {
    exact: true,
    guard: MainLayout,
    path: ['/ticket/:language/:ticketId'],
    component: lazy(() => import('src/views/ticket/Player.js')),
  },
  {
    path: '/app',
    guard: AuthGuard,
    layout: DashboardLayout,
    routes: [
      // overview views
      {
        exact: true,
        path: '/app/overview/dashboard',
        component: lazy(() => import('src/views/overview/Dashboard')),
      },
      {
        exact: true,
        path: '/app/overview/activities',
        component: lazy(() => import('src/views/overview/Activity')),
      },
      {
        exact: true,
        path: '/app/overview/transactions',
        component: lazy(() => import('src/views/overview/Transaction')),
      },
      {
        exact: true,
        path: '/app/overview/affiliates',
        component: lazy(() => import('src/views/overview/Affiliate')),
      },
      {
        exact: true,
        path: '/app/overview/charts/revenue',
        component: lazy(() => import('src/views/overview/Charts/Revenue')),
      },
      {
        exact: true,
        path: '/app/overview/charts/mrr',
        component: lazy(() => import('src/views/overview/Charts/MRR')),
      },
      {
        exact: true,
        path: '/app/overview/charts/new-trials',
        component: lazy(() => import('src/views/overview/Charts/NewTrials')),
      },
      {
        exact: true,
        path: '/app/overview/charts/active-subscriber-base',
        component: lazy(() =>
          import('src/views/overview/Charts/ActiveSubscriberBase'),
        ),
      },
      {
        exact: true,
        path: '/app/overview/charts/trial-start-conversion',
        component: lazy(() =>
          import('src/views/overview/Charts/TrialStartConversion'),
        ),
      },
      {
        exact: true,
        path: '/app/overview/charts/trial-to-paid-conversion',
        component: lazy(() =>
          import('src/views/overview/Charts/Trial2PaidConversion'),
        ),
      },
      {
        exact: true,
        path: '/app/overview/charts/subscription-conversion',
        component: lazy(() =>
          import('src/views/overview/Charts/SubscriptionConversion'),
        ),
      },
      {
        exact: true,
        path: '/app/overview/charts/churn',
        component: lazy(() => import('src/views/overview/Charts/Churn')),
      },
      {
        exact: true,
        path: '/app/overview/charts',
        component: () => <Redirect to="/app/overview/charts/revenue" />,
      },
      {
        exact: true,
        path: '/app/overview',
        component: () => <Redirect to="/app/overview/dashboard" />,
      },
      // management views
      {
        exact: true,
        path: '/app/management/campaigns/:campaignId',
        component: lazy(() => import('src/views/campaigns/CampaignDetailView')),
      },
      {
        exact: true,
        path: '/app/management/campaigns',
        component: lazy(() => import('src/views/campaigns/CampaignListView')),
      },
      {
        exact: true,
        path: [
          '/app/management/product-packages/create',
          '/app/management/product-packages/:productPackageId',
        ],
        component: lazy(() =>
          import('src/views/productPackage/ProductPackageFormView'),
        ),
      },
      {
        exact: true,
        path: '/app/management/product-packages',
        component: lazy(() =>
          import('src/views/productPackage/ProductPackageListView'),
        ),
      },
      {
        exact: true,
        path: [
          '/app/management/products/create',
          '/app/management/products/:productId',
        ],
        component: lazy(() => import('src/views/product/ProductFormView')),
      },
      {
        exact: true,
        path: '/app/management/products',
        component: lazy(() => import('src/views/product/ProductListView')),
      },
      {
        exact: true,
        path: [
          '/app/management/remote-config/create',
          '/app/management/remote-config/:remoteConfigId',
        ],
        component: lazy(() =>
          import('src/views/remoteConfig/RemoteConfigFormView'),
        ),
      },
      {
        exact: true,
        path: '/app/management/remote-config',
        component: lazy(() =>
          import('src/views/remoteConfig/RemoteConfigListView'),
        ),
      },
      {
        exact: true,
        path: [
          '/app/management/lua-remote-config/create',
          '/app/management/lua-remote-config/:luaRemoteConfigId',
        ],
        component: lazy(() =>
          import('src/views/luaRemoteConfig/LuaRemoteConfigFormView'),
        ),
      },
      {
        exact: true,
        path: '/app/management/lua-remote-config',
        component: lazy(() =>
          import('src/views/luaRemoteConfig/LuaRemoteConfigListView'),
        ),
      },
      {
        exact: true,
        path: [
          '/app/management/promo-codes/create',
          '/app/management/promo-codes/:promoCodeId',
        ],
        component: lazy(() => import('src/views/PromoCode/PromoCodeFormView')),
      },
      {
        exact: true,
        path: '/app/management/promo-codes',
        component: lazy(() => import('src/views/PromoCode/PromoCodeListView')),
      },
      {
        exact: true,
        path: [
          '/app/management/languages/:languageId/translations/create',
          '/app/management/languages/:languageId/translations/:translationId',
        ],
        component: lazy(() => import('src/views/language/TranslationFormView')),
      },
      {
        exact: true,
        path: '/app/management/languages/add',
        component: lazy(() => import('src/views/language/LanguageFormView')),
      },
      {
        exact: true,
        path: '/app/management/languages/:languageId',
        component: lazy(() => import('src/views/language/LanguageDetailsView')),
      },
      {
        exact: true,
        path: '/app/management/languages',
        component: lazy(() => import('src/views/language/LanguageListView')),
      },
      {
        exact: true,
        path: '/app/management/notification-templates',
        component: lazy(() => import('src/views/notificationTemplates')),
      },
      // {
      //   exact: true,
      //   path: ['/app/management/media/add', '/app/management/media/:mediaId'],
      //   component: lazy(() => import('src/views/media/MediaFormView')),
      // },
      // {
      //   exact: true,
      //   path: '/app/management/media',
      //   component: lazy(() => import('src/views/media/MediaListView')),
      // },
      // {
      //   exact: true,
      //   path: ['/app/management/music/add', '/app/management/music/:musicId'],
      //   component: lazy(() => import('src/views/music/MusicFormView')),
      // },
      // {
      //   exact: true,
      //   path: '/app/management/music',
      //   component: lazy(() => import('src/views/music/MusicListView')),
      // },
      // {
      //   exact: true,
      //   path: [
      //     '/app/management/meditation/add',
      //     '/app/management/meditation/:meditationId',
      //   ],
      //   component: lazy(() =>
      //     import('src/views/meditation/MeditationFormView'),
      //   ),
      // },
      // {
      //   exact: true,
      //   path: '/app/management/meditation',
      //   component: lazy(() =>
      //     import('src/views/meditation/MeditationListView'),
      //   ),
      // },
      {
        exact: true,
        path: '/app/management',
        component: () => <Redirect to="/app/management/users" />,
      },
      {
        exact: true,
        path: '/app/management/users/:userId',
        component: lazy(() => import('src/views/users/UserDetailsView')),
      },
      {
        exact: true,
        path: '/app/management/users',
        component: lazy(() => import('src/views/users/UserListView')),
      },
      // application views
      {
        exact: true,
        path: ['/app/tickets', '/app/tickets/:ticketId'],
        component: lazy(() => import('src/views/ticket')),
      },
      {
        path: '/app/logs',
        component: lazy(() => import('src/views/log/LogListView')),
      },
      // forms views
      {
        exact: true,
        path: '/app/forms/feedback',
        component: lazy(() => import('src/views/forms/Feedback')),
      },
      {
        exact: true,
        path: '/app/forms/contact-page',
        component: lazy(() => import('src/views/forms/ContactPage')),
      },
      {
        exact: true,
        path: '/app/forms/career-page',
        component: lazy(() => import('src/views/forms/CareerPage')),
      },
      // other views
      {
        exact: true,
        path: '/app/account',
        component: lazy(() => import('src/views/account/AccountView')),
      },
      {
        exact: true,
        path: '/app',
        component: () => <Redirect to="/app/overview/dashboard" />,
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
  {
    path: '/docs',
    layout: DocsLayout,
    routes: [
      {
        exact: true,
        path: '/docs',
        component: () => <Redirect to="/docs/welcome" />,
      },
      {
        exact: true,
        path: '/docs/welcome',
        component: lazy(() => import('src/views/docs/WelcomeView')),
      },
      {
        exact: true,
        path: '/docs/getting-started',
        component: lazy(() => import('src/views/docs/GettingStartedView')),
      },
      {
        exact: true,
        path: '/docs/postman',
        component: lazy(() => import('src/views/docs/PostmanView')),
      },
      {
        exact: true,
        path: '/docs/localization',
        component: lazy(() => import('src/views/docs/LocalizationView')),
      },
      {
        exact: true,
        path: '/docs/products',
        component: lazy(() => import('src/views/docs/ProductView')),
      },
      {
        exact: true,
        path: '/docs/platform',
        component: lazy(() => import('src/views/docs/PlatformView')),
      },
      {
        exact: true,
        path: '/docs/promo-codes',
        component: lazy(() => import('src/views/docs/PromoCodeView')),
      },
      {
        exact: true,
        path: '/docs/tickets',
        component: lazy(() => import('src/views/docs/TicketView')),
      },
      {
        exact: true,
        path: '/docs/support',
        component: lazy(() => import('src/views/docs/SupportView')),
      },
      {
        exact: true,
        path: '/docs/changelog',
        component: lazy(() => import('src/views/docs/ChangelogView')),
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
  {
    path: '*',
    routes: [
      {
        exact: true,
        path: '/',
        component: () => <Redirect to="/login" />,
      },
      {
        component: () => <Redirect to="/404" />,
      },
    ],
  },
];

export default routes;
