import { combineReducers } from '@reduxjs/toolkit';
// firestore
import {
  reducer as platformReducer,
  collection as platformCollection,
} from 'src/slices/platform';
import {
  reducer as productReducer,
  collection as productCollection,
} from 'src/slices/product';
import {
  reducer as promoCodeReducer,
  collection as promoCodeCollection,
} from 'src/slices/promoCode';
import {
  reducer as productPackageReducer,
  collection as productPackageCollection,
} from 'src/slices/productPackage';
import {
  reducer as languageReducer,
  collection as languageCollection,
} from 'src/slices/language';
import {
  reducer as translationReducer,
  collection as translationCollection,
} from 'src/slices/translation';
import {
  reducer as mediaReducer,
  collection as mediaCollection,
} from 'src/slices/media';
import {
  reducer as ticketReducer,
  collection as ticketCollection,
} from 'src/slices/ticket';
import {
  reducer as userReducer,
  collection as userCollection,
} from 'src/slices/users';
import {
  reducer as overviewReducer,
  collection as overviewCollection,
} from 'src/slices/overview';
import {
  reducer as documentReducer,
  collection as documentCollection,
} from 'src/slices/document';
import {
  reducer as activityReducer,
  collection as activityCollection,
} from 'src/slices/activity';
import {
  reducer as transactionReducer,
  collection as transactionCollection,
} from 'src/slices/transaction';
import {
  reducer as feedbackReducer,
  collection as feedbackCollection,
} from 'src/slices/feedback';
import {
  reducer as careerFormReducer,
  collection as careerFormCollection,
} from 'src/slices/careerForm';
import {
  reducer as contactFormReducer,
  collection as contactFormCollection,
} from 'src/slices/contactForm';
import { reducer as storageReducer } from 'src/slices/storage';
import {
  reducer as meditationReducer,
  collection as meditationCollection,
} from 'src/slices/meditation';
import {
  reducer as musicReducer,
  collection as musicCollection,
} from 'src/slices/music';
import {
  reducer as remoteConfigReducer,
  collection as remoteConfigCollection,
} from 'src/slices/remoteConfig';
import {
  reducer as luaRemoteConfigReducer,
  collection as luaRemoteConfigCollection,
} from 'src/slices/luaRemoteConfig';
import {
  reducer as affiliateReducer,
  collection as affiliateCollection,
} from 'src/slices/affiliate';
import { reducer as notificationCampaignsReducer } from 'src/slices/notificationCampaigns';
import { reducer as notificationTemplatesReducer } from 'src/slices/notificationTemplates';

const rootReducer = combineReducers({
  storage: storageReducer,
  notificationCampaigns: notificationCampaignsReducer,
  notificationTemplates: notificationTemplatesReducer,
  [platformCollection]: platformReducer,
  [productCollection]: productReducer,
  [promoCodeCollection]: promoCodeReducer,
  [productPackageCollection]: productPackageReducer,
  [languageCollection]: languageReducer,
  [translationCollection]: translationReducer,
  [mediaCollection]: mediaReducer,
  [ticketCollection]: ticketReducer,
  [userCollection]: userReducer,
  [overviewCollection]: overviewReducer,
  [documentCollection]: documentReducer,
  [activityCollection]: activityReducer,
  [transactionCollection]: transactionReducer,
  [feedbackCollection]: feedbackReducer,
  [careerFormCollection]: careerFormReducer,
  [contactFormCollection]: contactFormReducer,
  [meditationCollection]: meditationReducer,
  [musicCollection]: musicReducer,
  [remoteConfigCollection]: remoteConfigReducer,
  [luaRemoteConfigCollection]: luaRemoteConfigReducer,
  [affiliateCollection]: affiliateReducer,
});

export default rootReducer;
