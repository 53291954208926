import { createSlice } from '@reduxjs/toolkit';
import request from 'src/utils/request';

const initialState = {
  error: null,
  isLoading: false,
  templates: [],
  config: null,
  type: 'dailyMorning', // Default type
};

const slice = createSlice({
  name: 'notificationTemplates',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    endLoading(state) {
      state.isLoading = false;
    },
    setError(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
    setTemplates(state, action) {
      state.templates = action.payload;
    },
    setConfig(state, action) {
      state.config = action.payload;
    },
    setType(state, action) {
      state.type = action.payload;
    },
    resetState(state) {
      state.templates = [];
      state.config = null;
      state.error = null;
      state.isLoading = false;
    },
  },
});

export const reducer = slice.reducer;

export const {
  startLoading,
  endLoading,
  setError,
  setTemplates,
  setConfig,
  setType,
  resetState,
} = slice.actions;

// Fetch templates by type
export const fetchTemplates = type => async dispatch => {
  try {
    dispatch(startLoading());

    const response = await request.get(
      `/admin/notifications/templates?type=${type}`,
    );

    if (response.data && response.data.data && response.data.data.templates) {
      dispatch(setTemplates(response.data.data.templates));
    } else {
      dispatch(setTemplates([]));
    }

    dispatch(endLoading());
  } catch (error) {
    console.error('Error fetching templates:', error);
    dispatch(setError(error.message || 'Failed to fetch templates'));
  }
};

// Fetch configuration by type
export const fetchConfig = type => async dispatch => {
  try {
    dispatch(startLoading());

    const response = await request.get(
      `/admin/notifications/config?type=${type}`,
    );

    if (response.data && response.data.data && response.data.data.config) {
      dispatch(setConfig(response.data.data.config));
    }

    dispatch(endLoading());
  } catch (error) {
    console.error('Error fetching config:', error);
    dispatch(setError(error.message || 'Failed to fetch configuration'));
  }
};

// Create a new template
export const createTemplate = template => async (dispatch, getState) => {
  try {
    dispatch(startLoading());

    await request.post('/admin/notifications/templates', template);

    // Refresh templates after creating
    const { type } = getState().notificationTemplates;
    dispatch(fetchTemplates(type));

    return true;
  } catch (error) {
    console.error('Error creating template:', error);
    dispatch(setError(error.message || 'Failed to create template'));
    return false;
  }
};

// Update an existing template
export const updateTemplate = (id, template) => async (dispatch, getState) => {
  try {
    dispatch(startLoading());

    await request.put(`/admin/notifications/templates/${id}`, template);

    // Refresh templates after updating
    const { type } = getState().notificationTemplates;
    dispatch(fetchTemplates(type));

    return true;
  } catch (error) {
    console.error('Error updating template:', error);
    dispatch(setError(error.message || 'Failed to update template'));
    return false;
  }
};

// Delete a template
export const deleteTemplate = id => async (dispatch, getState) => {
  try {
    dispatch(startLoading());

    await request.delete(`/admin/notifications/templates/${id}`);

    // Refresh templates after deleting
    const { type } = getState().notificationTemplates;
    dispatch(fetchTemplates(type));

    return true;
  } catch (error) {
    console.error('Error deleting template:', error);
    dispatch(setError(error.message || 'Failed to delete template'));
    return false;
  }
};

// Toggle template active status
export const toggleTemplateActive = (id, active) => async (
  dispatch,
  getState,
) => {
  try {
    dispatch(startLoading());

    await request.patch(`/admin/notifications/templates/${id}/toggle`, {
      active,
    });

    // Refresh templates after toggling
    const { type } = getState().notificationTemplates;
    dispatch(fetchTemplates(type));

    return true;
  } catch (error) {
    console.error('Error toggling template status:', error);
    dispatch(setError(error.message || 'Failed to toggle template status'));
    return false;
  }
};

// Update configuration
export const updateConfig = (type, config) => async dispatch => {
  try {
    dispatch(startLoading());

    await request.put(`/admin/notifications/config/${type}`, config);

    await dispatch(fetchConfig(type));

    dispatch(endLoading());
    return true;
  } catch (error) {
    console.error('Error updating config:', error);
    dispatch(setError(error.message || 'Failed to update configuration'));
    return false;
  }
};

export default slice;
