import { createSlice } from '@reduxjs/toolkit';
import { noAuthRequest as request } from 'src/utils/request';

const initialState = {
  error: null,
  isLoading: false,
  campaigns: [],
  campaign: null,
  dailyStats: [],
  apiResponse: null,
  dailyStatsResponse: null,
};

const slice = createSlice({
  name: 'notificationCampaigns',
  initialState,
  reducers: {
    startLoading(state) {
      state.isLoading = true;
      state.error = null;
    },
    endLoading(state) {
      state.isLoading = false;
    },
    setError(state, action) {
      state.error = action.payload;
      state.isLoading = false;
    },
    setCampaigns(state, action) {
      state.campaigns = action.payload;
    },
    setCampaign(state, action) {
      state.campaign = action.payload;
    },
    setDailyStats(state, action) {
      state.dailyStats = action.payload;
    },
    setApiResponse(state, action) {
      state.apiResponse = action.payload;
    },
    setDailyStatsResponse(state, action) {
      state.dailyStatsResponse = action.payload;
    },
    resetState(state) {
      state.campaigns = [];
      state.campaign = null;
      state.dailyStats = [];
      state.error = null;
      state.apiResponse = null;
      state.dailyStatsResponse = null;
    },
  },
});

export const reducer = slice.reducer;

// Action to fetch all campaigns
export const fetchCampaigns = () => async dispatch => {
  dispatch(slice.actions.startLoading());

  try {
    const apiResponse = await request.get(`/notification-campaigns`);
    const response = apiResponse.data;

    dispatch(slice.actions.setApiResponse(response));

    const campaignData = response.data;

    dispatch(slice.actions.setCampaigns(campaignData));
  } catch (err) {
    dispatch(slice.actions.setError(err.message));
  }

  dispatch(slice.actions.endLoading());
};

// Action to fetch a single campaign
export const fetchCampaign = id => async dispatch => {
  dispatch(slice.actions.startLoading());

  try {
    const apiResponse = await request.get(`/notification-campaigns/${id}`);
    const response = apiResponse.data;

    dispatch(slice.actions.setApiResponse(response));

    const campaignData = response.data;

    dispatch(slice.actions.setCampaign(campaignData));
  } catch (err) {
    dispatch(
      slice.actions.setError(
        `Failed to fetch campaign details: ${err.message}`,
      ),
    );
  }

  dispatch(slice.actions.endLoading());
};

// Action to fetch daily stats for a campaign
export const fetchDailyStats = campaignName => async dispatch => {
  if (!campaignName) {
    return;
  }

  dispatch(slice.actions.startLoading());

  try {
    const apiResponse = await request.get(
      `/notification-campaigns/daily-stats/${campaignName}`,
    );
    const response = apiResponse.data;

    dispatch(slice.actions.setDailyStatsResponse(response));

    const statsData = response.data.dailyStats;

    dispatch(slice.actions.setDailyStats(statsData));
  } catch (err) {
    // We don't set error state here to avoid blocking the entire view
    // if only daily stats fail to load
  }

  dispatch(slice.actions.endLoading());
};

// Action to try a direct API call as a fallback
export const tryDirectApiCall = id => async dispatch => {
  // Reset any previous errors and set loading state
  dispatch(slice.actions.startLoading());
  dispatch(slice.actions.setError(null));
  dispatch(slice.actions.setApiResponse(null));

  try {
    const response = await request.get(
      `/notification-campaigns${id ? `/${id}` : ''}`,
    );

    dispatch(slice.actions.setApiResponse(response.data));

    if (response && response.data) {
      const campaignData = response.data.data;

      if (id) {
        // Single campaign
        dispatch(slice.actions.setCampaign(campaignData));
      } else {
        // List of campaigns
        dispatch(
          slice.actions.setCampaigns(
            Array.isArray(campaignData) ? campaignData : [campaignData],
          ),
        );
      }

      dispatch(slice.actions.endLoading());
      return response.data;
    }
  } catch (err) {
    const errorMessage = `Direct API call failed: ${err.message}`;
    dispatch(slice.actions.setError(errorMessage));

    dispatch(slice.actions.endLoading());
    throw err;
  }
};

export default slice;
